/** @format */

import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { ExclamationCircle, GeoAlt } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";

export default function CercaDeMi() {
  const [tiendaSeleccionada, setTiendaSeleccionada] = useState("");

  const [mostrar, setmostrar] = useState(false);

  const [tiendas, settiendas] = useState([]);

  useEffect(() => {
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/ubicaciones`, {
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          settiendas(await response.json());
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Layout>
      <div className="my-5 parent-index" style={{ backgroundColor: "#F0D860" }}>
        <Row className="text-center">
          <Col className="mt-5">
            <StaticImage className="child-smile-ubicaciones" src="../images/curva-azul.png" alt="" />
            <StaticImage className="child-ondas-ubicaciones" src="../images/ondas-agua-naranja.png" alt="" />
            <h1 className="mb-4 me-5 ms-4 titulo-grande">
              Encuentra nuestros productos en la tienda más cercana a ti.
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6}>
            <MarcoNombreTienda onClick={() => setmostrar((e) => !e)} texto="Elige la tienda">
              <GeoAlt />
            </MarcoNombreTienda>
            {mostrar &&
              tiendas.map(function (e) {
                return (
                  <MarcoNombreTienda
                    texto={e.nombre}
                    colorTexto={tiendaSeleccionada === e.nombre ? "orange" : "gray"}
                    onClick={() => {
                      setTiendaSeleccionada(e.nombre);
                      setmostrar(false);
                    }}
                  />
                );
              })}
          </Col>
        </Row>
      </div>

      <Row>
        <p className="subtitulo mb-2">Resultados {tiendaSeleccionada && ` en ${tiendaSeleccionada}`}</p>
        <p style={{ fontSize: 13, color: "gray" }}>
          <ExclamationCircle className="me-2" />
          La existencia de los productos dependerá del inventario que maneje la tienda.
        </p>
      </Row>
      <Row className="mb-lg-5 me-lg-5">
        {tiendas.map(function (e) {
          return (
            <>
              {e.nombre === tiendaSeleccionada &&
                e.ubicaciones.map(function (u) {
                  return (
                    <Col key={u.nombreUbicacion} lg={6}>
                      <MarcoDetalleTienda nombreUbicacion={u.nombreUbicacion} direccion={u.direccion} />
                    </Col>
                  );
                })}
            </>
          );
        })}
      </Row>
    </Layout>
  );
}

function MarcoNombreTienda({ texto, colorTexto, onClick, children }) {
  return (
    <div className="text-center mb-1 mx-auto ms-md-5 mx-lg-0 marco-nombre-tienda" onClick={onClick}>
      <span className="subtitulo" style={{ color: colorTexto || "black" }}>
        {children && <span className="me-2">{children}</span>}
        {texto}
      </span>
    </div>
  );
}

function MarcoDetalleTienda({ nombreUbicacion, direccion }) {
  return (
    <div
      style={{
        borderStyle: "solid",
        backgroundColor: "white",
        padding: 8,
        borderWidth: 2,
      }}
      className="mb-3 px-4"
    >
      <p className="mb-1" style={{ fontSize: 13, color: "gray" }}>
        <StaticImage src="../images/bolsa-compra.png" style={{ width: 10, marginRight: 10 }} alt="" />
        Ubicación
      </p>
      <p className="subtitulo mb-0">{nombreUbicacion}</p>
      <p className="mb-0" style={{ fontSize: 13, color: "gray" }}>
        {direccion}
      </p>
    </div>
  );
}
